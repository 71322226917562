import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import SelectLevel from "@/modules/select-level/select-level.vue";
import API from "@/api/system/master/post";

@Options({
    name: "flowable-components-post-picker",
    components: {
        "app-select-level": SelectLevel,
    },
    props: {
        //是否多选
        multiple: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Array,
            default: (): any => {
                return [];
            },
        },
    },
    emits: ["ok"],
})
export default class PostPicker extends BaseFunction {
    private multiple = false;
    private selected: any = [];
    private requestItem: any = {};

    //锁定列数据实体
    public getLockItem(): any {
        if (this.$tools.isEmpty(this.selected)) {
            return {
                type: "router",
                name: "post_id",
                title: "选择岗位",
                parent: -1,
                value: this.multiple ? {} : null,
            };
        } else {
            if (!this.multiple) {
                //单选
                return {
                    type: "router",
                    name: "post_id",
                    title: this.selected[0].title,
                    parent: this.selected[0].parent,
                    value: this.selected[0].value,
                };
            } else {
                //多选
                return {
                    type: "router",
                    name: "post_id",
                    title: this.selected[0].title,
                    parent: this.selected[0].parent,
                    value: this.selected,
                };
            }
        }
    }

    get API() {
        return API;
    }
    //搜索字段
    private searchField: any = [
        { field: "post_name", name: "名称" },
        { field: "post_remark", name: "说明" },
    ];
    //选择器打开
    public modalOpen() {
        this.$nextTick(() => {
            this.requestItem = this.getLockItem();
            this.$nextTick(() => {
                (this.$refs.picker as any).openModal();
            }).then();
        }).then();
    }
    //选择器提交
    public async submitFunction(item: any): Promise<void> {
        if (!this.multiple) {
            const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem() : item;
            this.requestItem.value = curItem.value;
            this.requestItem.title = curItem.title;
            this.requestItem.parent = curItem.parent;
            this.$emit("ok", [item]);
        } else {
            if (this.$tools.isEmpty(item)) {
                const curItem = this.getLockItem();
                this.requestItem.value = curItem.value;
                this.requestItem.title = curItem.title;
                this.requestItem.parent = curItem.parent;
            } else {
                this.requestItem.value = item;
                this.requestItem.title = item[0].title;
                this.requestItem.parent = item[0].parent;
            }
            this.$emit("ok", item);
        }
        return Promise.resolve();
    }
}
