import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-388e3e78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"10px"} }

export function render(_ctx, _cache) {
  const _component_el_tag = _resolveComponent("el-tag")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._value, (picker, index) => {
      return (_openBlock(), _createBlock(_component_el_tag, {
        class: "picker-item",
        type: "info",
        key: index + '_picker_item',
        closable: "",
        size: "default",
        onClose: $event => (_ctx.removePickerItem(index))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(picker.title), 1)
        ]),
        _: 2
      }, 1032, ["onClose"]))
    }), 128))
  ]))
}