import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
@Options({
    name: "flowable-components-picker-items",
    props: {
        modelValue: {
            type: Array,
            default: (): any => {
                return [];
            },
        },
    },
    emits: ["update:modelValue"],
})
export default class PickerItems extends BaseFunction {
    private modelValue: any;
    get _value() {
        return this.modelValue;
    }
    set _value(val) {
        this.$emit("update:modelValue", val);
    }
    public removePickerItem(index: any) {
        this._value.splice(index, 1);
    }
}
